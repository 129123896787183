<template>
    <div>
        <div class="top-wrap">
            <div class="num-box">
                <p class="num">今日收益：<span>{{info.income_today}}</span></p>
                <p class="num" v-if="info.income_all!=0">总收益：<span>{{info.income_all}}</span></p>
            </div>
            <div class="num-box">
                <a href="#/member" style="width:80%">
                    <p class="num">我的团队：<span>{{info.next_count}}</span>人</p>
                </a>
                <p class="num">上月新队员：<span>{{info.last_month_count}}</span>人</p>
                <p class="num">上月新队员活跃：<span>{{info.last_month_act_count}}</span>人</p>
            </div>
        </div>
        <div class="menu-wrap">
            <el-menu :defaultActive="navIndex" mode="horizontal" class="el-menu-vertical-demo" active-text-color="#49C265" :router="true">
                <el-menu-item :index="m.id" :key="m.id" v-for="m in navList" :route="{path:path, query: {cate:m.cate}}">{{m.name}}</el-menu-item>
            </el-menu>
            <div class="content">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        mapGetters,
        mapMutations
    } from "vuex";
    export default {
        data() {
            return {
                info: {},
                path: '/team_income',
                // type 	cate	1礼物 2视频 3语音 4文字 5其他
                navIndex: "0",
                navList: [{
                        name: "礼物",
                        id: "0",
                        cate: 1
                    },
                    {
                        name: "视频",
                        id: "1",
                        cate: 2
                    },
                    {
                        name: "语音",
                        id: "2",
                        cate: 3
                    },
                    {
                        name: "文字",
                        id: "3",
                        cate: 4
                    },
                    {
                        name: "其他",
                        id: "4",
                        cate: 5
                    }
                ],
            };
        },
        created() {
            // 在组件实例处理完所有与状态相关的选项后调用。
            this.path = this.$router.history.current.path;
            this.checkPath(this.$route);
        },
        mounted() {
            // 在组件被挂载之后调用。
        
            this.getTeamData();
        },
        methods: {
            ...mapMutations(["s_setUser"]),
            async getTeamData() {
                let {
                    code,
                    data
                } = await this.$y_ajax("api/member/teamDetail");
                if (code == 200) {
                    this.info = data;
                }
            },
            checkPath(t) {
                let q = t.query;
                let arr = this.navList;
                if (!q) return;
                for (let i = 0, len = arr.length; i < len; i++) {
                    if (q.cate == arr[i].cate) {
                        this.navIndex = arr[i].id;
                        break;
                    }
                }
            },
        },
        computed: {
            // 计算属性
            // ...mapGetters(["s_getUser"]),
        },
        watch: {
            // 监听
            $route(to, from) {
                // 路径判断
                this.checkPath(to);
            },
            // s_getUser(n) {
            //     if (!n) {
            //         this._home();
            //     }
            // },
        },
    };
</script>

<style lang="less" scoped>
    .menu-wrap {
        display: block;

        .iconfont {
            font-size: 20px;
            margin-right: 10px;
        }

        .content {
            min-height: 100vh;
            background: #fff;
            padding: 10px;
        }
    }

    .top-wrap {
        display: flex;
        padding: 10px;

        .num-box {
            width: 50%;

            .num {
                margin-bottom: 10px;

                span {
                    color: #49C265;
                }

            }
        }
    }

    /deep/.el-menu-item:focus,
    /deep/.el-menu-item:hover {
        // background-color: #ebf7ff;
        background-color: transparent;
    }

    /* 移动端 */
    @media (max-width: 600px) {
        .menu-wrap {
            .iconfont {
                margin-right: 0;
            }
        }

        .el-menu-vertical-demo {
            width: auto;
            position: sticky;
            position: -webkit-sticky;
            top: 0;
            z-index: 1;
            background: #fff;

            span {
                display: none;
            }
        }
    }
</style>